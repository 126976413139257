/*config.js*/
/*接口返回 code*/
export const API_RES_CODE_SUC   = [1,2,200];  //1:不弹窗  2弹窗
export const API_RES_CODE_ERR   = [0,777];
export const API_RES_CODE_WARN  = [];

//弹窗code 0：失败  2 成功
export const API_RES_CODE_ALERT   = [2,0,777];

// export const BASE_URL  = "//cloud.goomay.com.cn/";
// export const BASE_URL  = "//cloud-major.goomay.com/"; //测试版
export const BASE_URL  = "//api.goomay.cloud/"; //线上测试版
// export const BASE_URL  = "//api.cloud.goomay.com.cn/"; // 线上正式版

export const API_URL  =BASE_URL+ "api/";
// export const API_URL  = '//101.133.228.31:8101/adminapi/';


/*验证码图片*/
export const CAPTCHA_URL = API_URL+"captcha";

/*最大重试次数*/
export const MAX_RETRIES = 5;

/*默认超时时间（单位：毫秒）*/
export const DEFAULT_TIMEOUT = 10000;


/*默认 站点 lang 参数*/
// export const WEBSITE_LANG_DEFAULT = '';

/*网站管理  /website/menu.vue  menu_list pid*/
export const WEBSITE_MENU_PID = 10;